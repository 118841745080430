import Parse from "parse";
import config from "../config";

const PARSE_SERVER_URL = config.parseServerUrl
const PARSE_APP_ID = config.appId
const PARSE_JAVASCRIPT_KEY = config.javascriptKey
const PARSE_MASTER_KEY = config.masterKey

class ParseUtils {
  init = async () => {
    Parse.initialize(PARSE_APP_ID, PARSE_JAVASCRIPT_KEY, PARSE_MASTER_KEY);
    Parse.serverURL = PARSE_SERVER_URL
  };

  login = async userInfo => {

    if (!userInfo.username || !userInfo.password) {
      return {
        error: 'please fill in the input'
      };
    }
    const user = await Parse
      .User
      .logIn(userInfo.username, userInfo.password).catch(error => {
        // This will error, since the Parse.User is not authenticated
        console.log(`ERROR: ${error.message}`)
        return {
          error: error.message
        }
      });
      console.log(`USER:${JSON.stringify(user)}`)
    if (user && !user.error && user.get('isAdmin')) {
      var expireTime = Math.round((new Date()).getTime() / 1000);
      expireTime += 3600
      user.set('token', user.sessionToken);
      user.set('expireTime', expireTime);
      await user
        .save()
        .catch(error => {
          return {
            error: error
          }
        });
        const Activity = Parse.Object.extend('Activity');
        const obj = new Activity()
        obj.set('user',user)
        obj.set('type','AUTHENTICATION')
        obj.set('activity','LOGIN_SUCCESS')
        try {
            await obj.save()
        } catch (error) {
            console.log('LOGGER_ERROR: ',error)
        }
      return user
    }
    return {
      error: `Log in failed`
    }
  }

  currentUserCheck = async () => {
    Parse
      .User
      .currentAsync()
      .then(function (user) {
        if (user) {
          return true
        } else {
          return false
        }
      });
  }
}
export default new ParseUtils();