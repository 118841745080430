import Parse from "parse";
import config from "../config";
import moment from 'moment-timezone';
import axios from 'axios';
const PARSE_SERVER_URL = config.parseServerUrl
const PARSE_APP_ID = config.appId
const PARSE_JAVASCRIPT_KEY = config.javascriptKey
const PARSE_MASTER_KEY = config.masterKey


export const init = async () => {
    Parse.initialize(PARSE_APP_ID, PARSE_JAVASCRIPT_KEY, PARSE_MASTER_KEY);
    Parse.masterKey = PARSE_MASTER_KEY
    Parse.serverURL = PARSE_SERVER_URL
};
export const closeConnection = async () => {
    init()
    Parse
        .LiveQuery
        .close();
    return
}
export const getAllObjects = async (className, limit, skip, isTrash, queryData) => {
    init()
    const { dateFilter } = queryData || {}
    const ClassName = Parse.Object.extend(className);
    const query = new Parse.Query(ClassName);
    query.limit(limit)
    query.skip(skip)
    query.descending("createdAt");
    if (dateFilter && dateFilter.length !== 0) {
        query.greaterThanOrEqualTo('createdAt', new Date(dateFilter[0].startOf('day').toString()));
        query.lessThan('createdAt', new Date(dateFilter[1].endOf('day').toString()));
    }
    if (isTrash) {
        query.equalTo('isTrash', isTrash)
    } else {
        query.doesNotExist('isTrash')
    }
    const results = await query.find();
    var objs = []
    results.map((obj) => {
        const json = obj.toJSON()
        json.parseObject = obj
        objs.push(json)
    })
    console.log('query', query);

    console.log(`results length:${objs.length} | ${JSON.stringify(objs[0])}`)
    return objs
}
export const getObjectsCount = async (className, limit, isTrash) => {
    init()
    const ClassName = Parse.Object.extend(className);
    const query = new Parse.Query(ClassName);
    if (limit) {
        query.limit(limit)
    }
    if (isTrash) {
        query.equalTo('isTrash', isTrash)
    } else {
        query.doesNotExist('isTrash')
    }
    const results = await query.count();

    console.log(`results length:${results}`)
    return results
}

export const queryObjects = async (className, key, value, limit, skip, dateFilter, isTrash) => {
    init()
    const ClassName = Parse.Object.extend(className);
    const query = new Parse.Query(ClassName);
    query.limit(limit)
    query.skip(skip)
    if (isTrash) {
        query.equalTo('isTrash', isTrash)
    } else {
        query.doesNotExist('isTrash')
    }
    if (dateFilter.length !== 0) {
        query.greaterThanOrEqualTo('createdAt', new Date(dateFilter[0].startOf('day').toString()));
        query.lessThan('createdAt', new Date(dateFilter[1].endOf('day').toString()));
    }
    if (value !== "") {
        query.contains(key, value);
    }
    query.descending("createdAt");
    const count = await query.count();
    const results = await query.find();
    var objs = []
    results.map((obj) => {
        const json = obj.toJSON()
        json.parseObject = obj
        objs.push(json)
    })
    console.log(`results length:${results}`)
    return { results: objs, count: count }
}

export const queryObjectsCount = async (className, key, value, limit, skip, dateFilter, isTrash) => {
    init()
    const ClassName = Parse.Object.extend(className);
    const query = new Parse.Query(ClassName);
    query.limit(limit)
    query.skip(skip)
    if (isTrash) {
        query.equalTo('isTrash', isTrash)
    } else {
        query.doesNotExist('isTrash')
    }
    if (dateFilter.length !== 0) {
        query.greaterThanOrEqualTo('createdAt', new Date(dateFilter[0].startOf('day').toString()));
        query.lessThan('createdAt', new Date(dateFilter[1].endOf('day').toString()));
    }
    if (value !== "") {
        query.contains(key, value);
    }
    query.descending("createdAt");
    const count = await query.count();
    return count
}

export const queryObjectsByCreatedAt = async (className, start, end, limit, skip, isTrash) => {
    init()
    const ClassName = Parse.Object.extend(className);
    const query = new Parse.Query(ClassName);
    query.limit(limit)
    query.skip(skip)
    if (isTrash) {
        query.equalTo('isTrash', isTrash)
    } else {
        query.doesNotExist('isTrash')
    }
    query.greaterThanOrEqualTo('createdAt', start);
    query.lessThan('createdAt', end);

    const count = await query.count();
    const results = await query.find();
    var objs = []
    results.map((obj) => {
        const json = obj.toJSON()
        json.birth = json.birth.iso
        json.expiryDate = json.expiryDate.iso
        objs.push(json)
    })
    console.log(`results length:${results}`)
    return { results: objs, count: count }
}


export const trashObjectWithId = async (className, objectId, trashBool) => {
    init()
    const Class = Parse.Object.extend(className);
    const query = new Parse.Query(Class);
    const result = await query.get(objectId)
    if (result) {
        if (trashBool) {
            result.set('isTrash', trashBool)
        } else {
            result.unset('isTrash')
        }

        const res = await result.save().catch(error => {
            return { type: 'error', msg: error.message }
        })
        return { type: 'success', msg: 'Done!' }
    } else {
        return { type: 'error', msg: `Can\'t find ${className} with this ID` }
    }
}

export const getObjectWithId = async (className, objectId) => {
    init()
    const Class = Parse.Object.extend(className);
    const query = new Parse.Query(Class);
    const result = await query.get(objectId)

    console.log(`getObjectWithId :${JSON.stringify(result)}`)
    return result
}
export const destroyObjectWithId = async (className, objectId) => {
    init()

    const Class = Parse.Object.extend(className);
    const query = new Parse.Query(Class);
    const result = await query.get(objectId)

    const res = await result.destroy().catch((error) => {
        console.log(`destroyObjectWithId error:${JSON.stringify(error)}`)
        return { type: 'error', msg: 'Destroy Error! :' + error.message }
    });
    return { type: 'success', msg: 'Destroy Done!' }

}


export const createObject = async (className, req) => {
    init()
    console.log(`createObject:${JSON.stringify(req)}`)

    const ClassName = Parse.Object.extend(className);
    const obj = new ClassName();
    try {
        const res = await obj.save(req)
        var acl = new Parse.ACL();
        acl.setPublicReadAccess(true);
        acl.setPublicWriteAccess(true);
        res.setACL(acl);
        await res.save()
        return { type: 'success', msg: 'Done!', objectId: res.id, object: res }
    } catch (error) {
        return { type: 'error', msg: 'Error! :' + error.message }
    }


}

export const editObject = async (req) => {
    init()
    console.log(`editObject:${JSON.stringify(req)}`)
    await req.save().catch(error => {
        return { type: 'error', msg: 'Error! :' + error.message }
    })
    return { type: 'success', msg: 'Done!' }
}

export const editMember = async req => {
    init()

    var obj = await getObjectWithId('Member', req.objectId)
    obj.set('memberId', req.memberId)
    obj.set('name', req.name)
    obj.set('surName', req.surName)
    obj.set('sex', req.sex)
    obj.set('birth', req.birth)
    obj.set('birthTimestamp', req.birthTimestamp)
    obj.set('ageNum', parseInt(req.age))
    obj.set('home', req.home)
    obj.set('mobile', req.mobile)
    obj.set('email', req.email)
    obj.set('line', req.line)
    obj.set('facebook', req.facebook)
    obj.set('addr1', req.addr1)
    obj.set('addr2', req.addr2)
    obj.set('addr3', req.addr3)
    obj.set('addr4', req.addr4)
    obj.set('addr5', req.addr5)
    obj.set('district', req.district)
    obj.set('province', req.province)
    obj.set('zipCode', req.zipCode)
    obj.set('zipcode2digit', req.zipCode.substr(0, 2))
    obj.set('isUsedService', req.isUsedService == "true" ? true : false)
    obj.set('otherHospital', req.otherHospital)
    if (req.expiryDate) {
        obj.set('expiryDate', new Date(req.expiryDate))
    }


    const res = await obj.save().catch(error => {
        return { type: 'error', msg: error.message }
    })
    return { type: 'success', msg: "Save done!", member: res }
}

export const saveAllObject = async req => {
    init()
    console.log(`saveAllObject req`, req)
    const res = await Parse.Object.saveAll(req).catch(error => {
        return { type: 'error', msg: 'Error! :' + error.message }
    })
    return { type: 'success', msg: 'Done!' }
}
export const fetchAllObject = async req => {
    init()
    console.log(`saveAllObject req`, req)
    const res = await Parse.Object.fetchAll(req).catch(error => {
        return { type: 'error', msg: 'Error! :' + error.message }
    })
    return res
}
export const destroyAllObject = async req => {
    init()
    const res = await Parse.Object.destroyAll(req).catch(error => {
        return { type: 'error', msg: 'Error! :' + error.message }
    })
    return { type: 'success', msg: 'Done!' }
}

export const uploadParseFile = async file => {
    init()
    const uuid = new Date().getTime()
    console.log(`upload uuid :  ${uuid}`)
    var parseFile = new Parse.File(`${uuid}.${file.type === 'image/jpeg' ? 'jpg' : 'png'}`, file.originFileObj);
    try {
        const img = await parseFile.save()
        console.log(`upload img.url :  ${img.url()}`)
        const File = Parse.Object.extend("File")
        const obj = new File()
        obj.set('file', img)
        obj.save()
        return { url: img.url(), img: img }
    } catch (error) {
        return { type: 'error', msg: 'Error! :' + error.message }
    }
}

export const uploadParseThumbnailFile = async file => {
    init()
    const uuid = new Date().getTime()
    console.log(`upload uuid :  ${uuid}`)
    var parseFile = new Parse.File(`${uuid}.jpg`, { base64: file });
    const img = await parseFile.save().catch(error => {
        console.log(`upload error :  ${error.message}`)

        return { type: 'error', msg: 'Error! :' + error.message }
    })
    console.log(`upload img.url :  ${img.url()}`)
    const File = Parse.Object.extend("Thumbnail")
    const obj = new File()
    obj.set('file', img)
    obj.save()
    return { url: img.url(), img: img }

}

export const countMemberFromQuery = async (ageRange, province, isUsedService, sex) => {
    init()
    const query = new Parse.Query('Member');
    if (ageRange) {
        console.log(`ageRange:${ageRange}`)
        query.greaterThanOrEqualTo("ageNum", ageRange[0]);
        query.lessThanOrEqualTo("ageNum", ageRange[1]);
    }

    if (province && province != [] && province != '') {
        console.log(`province:${province}`)
        query.containedIn("zipcode2digit", province);
    }

    if (isUsedService) {
        console.log(`isUsedService:${isUsedService}`)
        query.equalTo("isUsedService", isUsedService);
    }
    if (sex) {
        console.log(`sex:${sex}`)
        query.equalTo("sex", sex);
    }
    query.doesNotExist('isTrash')
    const res = await query.count()
    console.log(`countMemberFromQuery res:${res}`)
    return res
}

export const getCouponByMember = async (member) => {
    init()
    const querySex1 = new Parse.Query('Coupon')
    querySex1.equalTo('sex_target', null)

    const querySex2 = new Parse.Query('Coupon')
    querySex2.equalTo('sex_target', member.sex)
    const querySex = Parse.Query.or(querySex2, querySex1)

    const queryProvince1 = new Parse.Query('Coupon')
    queryProvince1.equalTo('province_target', null)
    const queryProvince2 = new Parse.Query('Coupon')
    queryProvince2.equalTo('province_target', member.zipcode2digit)
    const queryProvince = Parse.Query.or(queryProvince1, queryProvince2)

    const queryIsUsed1 = new Parse.Query('Coupon')
    queryIsUsed1.equalTo('isUsedService_target', null)
    const queryIsUsed2 = new Parse.Query('Coupon')
    queryIsUsed2.equalTo('isUsedService_target', member.isUsedService)
    const queryIsUsed = Parse.Query.or(queryIsUsed1, queryIsUsed2)

    const queryAge = new Parse.Query('Coupon')
    queryAge.lessThanOrEqualTo('maxAgeTimestamp', member.birthTimestamp)
    queryAge.greaterThanOrEqualTo('minAgeTimestamp', member.birthTimestamp)

    const mainQuery = Parse.Query.and(querySex, queryProvince, queryIsUsed, queryAge);

    mainQuery.doesNotExist('isTrash')
    mainQuery.exclude('content', 'cover')
    const res = await mainQuery.find()
    const nowUnix = new Date().getTime()
    console.log(`nowUnix:${JSON.stringify(res)}`)

    const last = []
    const mapPromises = res.map(async (obj) => {
        const start = obj.get('startDate').getTime()
        const end = obj.get('expiryDate').getTime()
        const limit = obj.get('limit')
        const usage = obj.get('usage')
        const quota = obj.get('quota')


        if (start <= nowUnix && end >= nowUnix) {
            const usageCount = await countUsageCouponByUserAndCoupon(member.objectId, obj.id)
            console.log(`usageCount :${usageCount} `)
            if (limit == null || usage < limit || quota == null || usageCount < quota) {
                last.push(obj.toJSON())
            }
        }
    })
    await Promise.all(mapPromises)

    console.log(`getCouponByMember res:${JSON.stringify(last)}`)
    return last
}

export const countUsageCouponByUserAndCoupon = async (memberObjectId, couponObjectId) => {
    init()
    const query = new Parse.Query('orderTransaction')
    query.equalTo('memberObjectId', memberObjectId)
    query.equalTo('couponObjectId', couponObjectId)
    const res = await query.count()
    console.log(`countUsageCouponByUserAndCoupon res:${res}`)
    return res
}

export const getUsageCouponByUser = async (memberObjectId) => {
    init()
    const query = new Parse.Query('orderTransaction')
    query.equalTo('memberObjectId', memberObjectId)
    const res = await query.find().catch(error => {
        return { type: 'error', msg: 'Error! :' + error.message }
    })
    console.log(`results getUsageCouponByUser:${JSON.stringify(res)}`)
    return { type: 'success', msg: 'Done!', objects: res }
}

export const getMemberByUsageCoupon = async (couponObjectId) => {
    init()
    const query = new Parse.Query('orderTransaction')
    query.equalTo('couponObjectId', couponObjectId)
    const res = await query.find().catch(error => {
        return { type: 'error', msg: 'Error! :' + error.message }
    })
    console.log(`results getUsageCouponByUser:${JSON.stringify(res)}`)
    return { type: 'success', msg: 'Done!', objects: res }
}

export const queryMemberById = async (value, limit, skip, isTrash) => {
    init()
    const ClassName = Parse.Object.extend('Member');
    const query = new Parse.Query(ClassName);
    query.limit(limit)
    query.skip(skip)
    if (isTrash) {
        query.equalTo('isTrash', isTrash)
    } else {
        query.doesNotExist('isTrash')
    }
    query.equalTo('memberId', value);
    const count = await query.count();
    const results = await query.find();
    var objs = []
    results.map((obj) => {
        const json = obj.toJSON()
        json.parseObject = obj
        objs.push(json)
    })
    console.log(`results length:${results}`)
    return { results: objs, count: count }
}

export const getTop5Coupon = async () => {
    init()
    const ClassName = Parse.Object.extend('Coupon');
    const query = new Parse.Query(ClassName);
    query.limit(5)
    query.doesNotExist('isTrash')
    query.descending("usage");
    const results = await query.find();
    var objs = []
    results.map((obj) => {
        const json = obj.toJSON()
        json.parseObject = obj
        objs.push(json)
    })
    return objs
}

export const calculateCouponValue = async () => {
    init()
    const ClassName = Parse.Object.extend('Coupon');
    const query = new Parse.Query(ClassName);
    query.doesNotExist('isTrash')
    query.descending("usage");
    const results = await query.find();
    var values = 0
    results.map((obj) => {
        const json = obj.toJSON()
        var v = json.usage * json.value
        values += v
    })
    return values
}

export const setPromoFeature = async (objectId, isFeature) => {
    init()
    const ClassName = Parse.Object.extend('Promotion');
    const query = new Parse.Query(ClassName);
    const result = await query.get(objectId);
    result.set('isFeature', isFeature)
    await result.save().catch(error => {
        return { type: 'error', msg: error.message }
    })
    return { type: 'success', msg: "Save done!" }

}

export const setRestaurantFeature = async (objectId, isFeature) => {
    init()
    const ClassName = Parse.Object.extend('Restaurant');
    const query = new Parse.Query(ClassName);
    const result = await query.get(objectId);
    result.set('isFeatured', isFeature)
    await result.save().catch(error => {
        return { type: 'error', msg: error.message }
    })
    return { type: 'success', msg: "Save done!" }

}

export const setPaymentStatus = async (className, objectId, status) => {
    init()
    const ClassName = Parse.Object.extend(className);
    const query = new Parse.Query(ClassName);
    const result = await query.get(objectId);
    result.set('paymentStatus', status)
    const data = result.get('log') || {}
    data[status] = Date.now()
    result.set('log', data)
    await result.save().catch(error => {
        return { type: 'error', msg: error.message }
    })
    return { type: 'success', msg: "Save done!" }
}
export const incrementWallet = async (riderId, amount) => {
    init()
    const ClassName = Parse.Object.extend('Rider');
    const query = new Parse.Query(ClassName);
    const result = await query.get(riderId);
    result.increment('wallet', amount)
    await result.save().catch(error => {
        return { type: 'error', msg: error.message }
    })
    return { type: 'success', msg: "Save done!" }
}


export const queryObjectBySomeKey = async (className, key, value, limit, skip, isTrash) => {
    init()
    const ClassName = Parse.Object.extend(className);
    const query = new Parse.Query(ClassName);
    query.limit(limit)
    query.skip(skip)
    if (isTrash) {
        query.equalTo('isTrash', isTrash)
    } else {
        query.doesNotExist('isTrash')
    }
    query.equalTo(key, value);
    const count = await query.count();
    const results = await query.find();
    var objs = []
    results.map((obj) => {
        const json = obj.toJSON()
        json.parseObject = obj
        objs.push(json)
    })
    console.log(`results length:${results}`)
    return { results: objs, count: count }
}

export const getNameById = async (className, objectId) => {
    init()
    const ClassName = Parse.Object.extend(className);
    const query = new Parse.Query(ClassName);
    const result = await query.get(objectId);
    return result.get('name')
}
export const getValueById = async (className, key, objectId) => {
    init()
    const ClassName = Parse.Object.extend(className);
    const query = new Parse.Query(ClassName);
    const result = await query.get(objectId);
    return result.get(key)
}
export const getLockName = async (places) => {
    init()
    const promises = await places.map(async (place) => {
        const query = new Parse.Query('Place');
        const result = await query.get(place.objectId);
        return result.get('name');
    })
    const lock = await Promise.all(promises)
    return lock.join(', ')
}

export const quickCreateFood = async (restaurantId, menuType, value) => {
    init()
    const ClassName = Parse.Object.extend('Food');
    const arr = value.split('\n')
    let items = []
    arr.map((item) => {
        const val = item.split(',')
        const obj = new ClassName();
        obj.set('restaurantId', restaurantId)
        obj.set('menuType', menuType)
        obj.set('name', val[0])
        obj.set('price', parseInt(val[1]))
        obj.set('salePrice', parseInt(val[1]))
        obj.set('status', true)
        items.push(obj)
    })

    const res = await Parse.Object.saveAll(items).catch(error => {
        return { type: 'error', msg: 'Error! :' + error.message }
    })
    return { type: 'success', msg: 'Done!' }
}

export const createPlace = async req => {
    init()
    console.log(`createPlace req :${req}`)
    const { marketType, zone, lockName, lockRange, price } = req
    const ClassName = Parse.Object.extend('Place');
    let objs = []
    if (lockRange) {
        for (let index = lockRange[0]; index <= lockRange[1]; index++) {
            //const place = index<10 ?`${zone}-0${index}`:`${zone}-${index}`
            const place = `${zone}-${index}`
            const obj = new ClassName();
            obj.set('marketType', marketType)
            obj.set('zone', zone)
            obj.set('name', place)
            obj.set('price', price)
            obj.set('index', index)
            objs.push(obj)
        }
    } else {
        const items = lockName.split(',')
        items.map((item) => {
            const place = `${zone}-${item}`
            const obj = new ClassName();
            obj.set('marketType', marketType)
            obj.set('zone', zone)
            obj.set('name', place)
            obj.set('price', price)
            objs.push(obj)
        })
    }

    const res = await Parse.Object.saveAll(objs).catch(error => {
        return { type: 'error', msg: 'Error! :' + error.message }
    })
    return { type: 'success', msg: 'Done!' }
}

export const verifyUser = async (objectId, status) => {
    init()
    const ClassName = Parse.Object.extend('User');
    const query = new Parse.Query(ClassName);
    const result = await query.get(objectId);
    result.set('status', status)
    const data = result.get('log') || {}
    data[status] = Date.now()
    result.set('log', data)
    await result.save().catch(error => {
        return { type: 'error', msg: error.message }
    })
    return { type: 'success', msg: "Save done!" }
}


export const customerTypeSwitch = async (objectId, status) => {
    init()
    const ClassName = Parse.Object.extend('User');
    const query = new Parse.Query(ClassName);
    const result = await query.get(objectId);
    result.set('customerType', status)
    const data = result.get('log') || {}
    data[status] = Date.now()
    result.set('log', data)
    await result.save().catch(error => {
        return { type: 'error', msg: error.message }
    })
    return { type: 'success', msg: "Save done!" }
}

export const resetPassword = async (objectId, password) => {
    const ClassName = Parse.Object.extend('User');
    const query = new Parse.Query(ClassName);
    const user = await query.get(objectId);
    user.set('password', password)
    try {
        await user.save()
        return ({ type: 'success', msg: 'เรียบร้อย' })
    } catch (error) {
        return ({ type: 'error', msg: error.message })
    }
}

export const editBranch = async (objectId, str) => {
    const ClassName = Parse.Object.extend('User');
    const query = new Parse.Query(ClassName);
    const user = await query.get(objectId);
    user.set('branch', str)
    try {
        await user.save()
        return ({ type: 'success', msg: 'เรียบร้อย' })
    } catch (error) {
        return ({ type: 'error', msg: error.message })
    }
}

export const getUserPaymentInfo = async (objectId) => {
    const userQuery = new Parse.Query('User');
    const user = await userQuery.get(objectId);
    const daily = await getOrderFromRange(user, 'day')
    const monthly = await getOrderFromRange(user, 'month')
    const yearly = await getOrderFromRange(user, 'year')
    //return [{name:"รายวัน",data:daily},{name:"รายเดือน",data:monthly},{name:"รายปี",data:yearly}]
    return [
        { name: 'ค่าล็อค', daily: daily.totalLock, monthly: monthly.totalLock, yearly: yearly.totalLock },
        { name: 'ค่าไฟ', daily: daily.electricity, monthly: monthly.electricity, yearly: yearly.electricity },
        { name: 'ค่าพัดลม', daily: daily.fan, monthly: monthly.fan, yearly: yearly.fan },
        { name: 'ค่าเต็นท์', daily: daily.tent, monthly: monthly.tent, yearly: yearly.tent },
        { name: 'ค่าฝากของ', daily: daily.tolley, monthly: monthly.tolley, yearly: yearly.tolley },
        { name: 'ค่าเข็นรถเข็น', daily: daily.tolleyService, monthly: monthly.tolleyService, yearly: yearly.tolleyService },
        { name: 'ค่าจอดรถ', daily: daily.car, monthly: monthly.car, yearly: yearly.car },
        { name: 'ค่าปรับ', daily: daily.fee, monthly: monthly.fee, yearly: yearly.fee },
        { name: 'รวม', daily: daily.total, monthly: monthly.total, yearly: yearly.total }
    ]
}


export const getOrderFromRange = async (user, range) => {
    init()
    let query = new Parse.Query('Order');
    query.equalTo('user', user)
    query.greaterThanOrEqualTo('updatedAt', moment().startOf(range).toDate())
    query.lessThan('updatedAt', moment().endOf(range).toDate())
    query.descending('updatedAt')
    query.limit(9999)
    let res = await query.find();
    let payment = {
        total: 0,
        totalLock: 0,
        electricity: 0,
        fan: 0,
        tent: 0,
        tolley: 0,
        tolleyService: 0,
        car: 0,
        fee: 0
    }
    for (const object of res) {
        let data = object.toJSON()
        const facilityData = data.facilityData
        payment.total += data.total
        payment.totalLock += data.totalLock
        payment.electricity += facilityData.electricity
        payment.fan += facilityData.fan
        payment.tent += facilityData.tent
        payment.tolley += facilityData.tolley
        payment.tolleyService += facilityData.tolleyService
        payment.car += facilityData.car
        payment.fee += facilityData.fee
    }

    return payment
}

export const getAvailablePlace = async (marketType, dateStr) => {
    init()
    const Place = Parse
        .Object
        .extend('Place');
    const Ticket = Parse
        .Object
        .extend('Ticket');
    const query = new Parse.Query(Place);
    query.equalTo('marketType', marketType)
    query.limit(9999)
    query.ascending('name')
    query.doesNotExist('isTrash')
    const results = await query.find();
    var objs = []
    //TODO: union with today ticket
    const query2 = new Parse.Query(Ticket);
    query2.equalTo('reservedDateStr', dateStr)
    query2.equalTo('marketType', marketType)
    const results2 = await query2.find();
    console.log('results2', results2)
    results.map((obj) => {
        const json = obj.toJSON()
        json.parseObject = obj
        if (!results2.some(obj2 => obj.get('name') === obj2.get('lockName'))) {
            objs.push(json)
        }
    })
    return objs
}

export const getAllPlace = async (marketType) => {
    init()
    const Place = Parse
        .Object
        .extend('Place');

    const query = new Parse.Query(Place);
    query.equalTo('marketType', marketType)
    query.limit(9999)
    query.ascending('index')
    query.doesNotExist('isTrash')
    const results = await query.find();
    var objs = []
    results.map((obj) => {
        const json = obj.toJSON()
        json.parseObject = obj
        objs.push(json)
    })
    return objs
}

export const listenTicket = async (marketType, dateStr) => {
    if (!dateStr) {
        dateStr = moment().format('YYYY-MM-DD')
    }
    let query = new Parse.Query('Ticket');
    query.equalTo('reservedDateStr', dateStr)
    query.equalTo('marketType', marketType)
    query.limit(9999)
    let subscription = await query.subscribe();
    return subscription

}

export const getTicket = async (marketType, dateStr) => {
    if (!dateStr) {
        dateStr = moment().format('YYYY-MM-DD')
    }
    let query = new Parse.Query('Ticket');
    query.equalTo('reservedDateStr', dateStr)
    query.equalTo('marketType', marketType)
    query.limit(9999)
    let objs = []
    let results = await query.find();
    results.map((ticket) => {
        objs.push(ticket.get('lockName'))
    })
    return objs

}

export const canceledOrder = async (data) => {
    try {
        const res = await axios.post(`${config.url}/api/1.0/order/cancel`, data)
        console.log(`canceled order ${JSON.stringify(res)}`)
        return res.status === 200
            ? res.data
            : {
                error: 'error'
            }
    } catch (error) {
        console.log(`getAllFeed error${JSON.stringify(error)}`)
        return error
    }
}

export const restoredOrder = async (data) => {
    try {
        const res = await axios.post(`${config.url}/api/1.0/order/restore`, data)
        console.log(`restore order ${JSON.stringify(res)}`)
        return res.status === 200
            ? res.data
            : {
                error: 'error'
            }
    } catch (error) {
        console.log(`getAllFeed error${JSON.stringify(error)}`)
        return error
    }
}


export const getOrderFromDateRange = async (className, fromDate, toDate, marketType) => {
    init()
    let query = new Parse.Query(className);
    query.notEqualTo('paymentStatus', 'canceled')
    if (marketType) {
        query.equalTo('marketType', marketType)
    }
    console.log('fromDate', fromDate)
    console.log('toDate', toDate)

    query.greaterThanOrEqualTo('fromDate', fromDate)
    query.lessThanOrEqualTo('toDate', toDate)
    query.limit(99999)
    let res = await query.find();
    console.log('getOrderFromDateRange', res)
    let payment = {
        all: {
            total: 0,
            totalLock: 0,
            electricity: 0,
            fan: 0,
            tent: 0,
            tolley: 0,
            tolleyService: 0,
            car: 0,
            fee: 0
        },
        paid: {
            total: 0,
            totalLock: 0,
            electricity: 0,
            fan: 0,
            tent: 0,
            tolley: 0,
            tolleyService: 0,
            car: 0,
            fee: 0
        },
        unpaid: {
            total: 0,
            totalLock: 0,
            electricity: 0,
            fan: 0,
            tent: 0,
            tolley: 0,
            tolleyService: 0,
            car: 0,
            fee: 0
        }
    }
    for (const object of res) {
        let data = object.toJSON()
        const facilityData = data.facilityData
        const dayCount = data.reservedDate.length
        payment.all.total += data.total
        payment.all.totalLock += data.totalLock || 0 * dayCount
        payment.all.electricity += facilityData.electricity * dayCount
        payment.all.fan += facilityData.fan * dayCount
        payment.all.tent += facilityData.tent * dayCount
        payment.all.tolley += facilityData.tolley * dayCount
        payment.all.tolleyService += facilityData.tolleyService * dayCount
        payment.all.car += facilityData.car * dayCount
        payment.all.fee += facilityData.fee * dayCount
        if (data.paymentStatus === 'completed') {
            payment.paid.total += data.total
            payment.paid.totalLock += data.totalLock || 0 * dayCount
            payment.paid.electricity += facilityData.electricity * dayCount
            payment.paid.fan += facilityData.fan * dayCount
            payment.paid.tent += facilityData.tent * dayCount
            payment.paid.tolley += facilityData.tolley * dayCount
            payment.paid.tolleyService += facilityData.tolleyService * dayCount
            payment.paid.car += facilityData.car * dayCount
            payment.paid.fee += facilityData.fee * dayCount
        } else {
            payment.unpaid.total += data.total
            payment.unpaid.totalLock += data.totalLock || 0 * dayCount
            payment.unpaid.electricity += facilityData.electricity * dayCount
            payment.unpaid.fan += facilityData.fan * dayCount
            payment.unpaid.tent += facilityData.tent * dayCount
            payment.unpaid.tolley += facilityData.tolley * dayCount
            payment.unpaid.tolleyService += facilityData.tolleyService * dayCount
            payment.unpaid.car += facilityData.car * dayCount
            payment.unpaid.fee += facilityData.fee * dayCount
        }

    }

    return [
        { name: 'ค่าล็อค', all: payment.all.totalLock, paid: payment.paid.totalLock, unpaid: payment.unpaid.totalLock },
        { name: 'ค่าไฟ', all: payment.all.electricity, paid: payment.paid.electricity, unpaid: payment.unpaid.electricity },
        { name: 'ค่าพัดลม', all: payment.all.fan, paid: payment.paid.fan, unpaid: payment.unpaid.fan },
        { name: 'ค่าเต็นท์', all: payment.all.tent, paid: payment.paid.tent, unpaid: payment.unpaid.tent },
        { name: 'ค่าฝากของ', all: payment.all.tolley, paid: payment.paid.tolley, unpaid: payment.unpaid.tolley },
        { name: 'ค่าเข็นรถเข็น', all: payment.all.tolleyService, paid: payment.paid.tolleyService, unpaid: payment.unpaid.tolleyService },
        { name: 'ค่าจอดรถ', all: payment.all.car, paid: payment.paid.car, unpaid: payment.unpaid.car },
        { name: 'ค่าปรับ', all: payment.all.fee, paid: payment.paid.fee, unpaid: payment.unpaid.fee },
        { name: 'รวม', all: payment.all.total, paid: payment.paid.total, unpaid: payment.unpaid.total }
    ]
}

Array.prototype.sumByKey = function (prop) {
    let sum = this.map(o => prop.split('.').reduce((r, val) => { return r ? r[val] : undefined; }, o)).reduce((a, c) => { return a + c }, 0);
    return sum
}

export const getOrderFromDateRangeAndUser = async (fromDate, toDate, marketType, user) => {
    init()
    let query = new Parse.Query('Order');
    query.notEqualTo('paymentStatus', 'canceled')
    if (marketType) {
        query.equalTo('marketType', marketType)
    }
    query.equalTo('user', user)
    if (fromDate || toDate) {
        query.greaterThanOrEqualTo('fromDate', fromDate.toDate())
        query.lessThanOrEqualTo('toDate', toDate.toDate())
    }
    query.limit(99999)
    let res = await query.find();
    console.log('getOrderFromDateRangeAndUser', res)
    let objs = []
    for (const object of res) {
        let data = object.toJSON()
        const reservedDate = data.reservedDate
        reservedDate.map((dateStr, i) => {
            const newTotal = data.total / reservedDate.length
            if (data.paymentStatus === 'completed') {
                data.paid = newTotal
                data.unpaid = 0
            } else {
                data.unpaid = newTotal
                data.paid = 0
            }
            objs.push({ ...data, reservedDateStr: dateStr, total: newTotal })
        })
    }
    const summary = {
        reservedDateStr: 'รวม',
        totalLock: objs.sumByKey('totalLock'),
        total: objs.sumByKey('total'),
        paid: objs.sumByKey('paid'),
        unpaid: objs.sumByKey('unpaid'),
        facilityData: {
            electricity: objs.sumByKey('facilityData.electricity'),
            fan: objs.sumByKey('facilityData.fan'),
            tent: objs.sumByKey('facilityData.tent'),
            tolley: objs.sumByKey('facilityData.tolley'),
            tolleyService: objs.sumByKey('facilityData.tolleyService'),
            car: objs.sumByKey('facilityData.car'),
            fee: objs.sumByKey('facilityData.fee'),
        }
    }
    objs.push(summary)
    return objs.sort((a, b) => {
        if (a.reservedDate < b.reservedDate)
            return -1;
        if (a.reservedDate > b.reservedDate)
            return 1;
        return 0;
    })
}

export const changeOrderReservedDate = async (data) => {
    init()
    const { objectId, reservedDate, fromDate, toDate, note } = data
    let orderQuery = new Parse.Query('Order');
    const order = await orderQuery.get(objectId)
    const places = order.get('places')

    const ticketQuery = new Parse.Query('Ticket');
    ticketQuery.greaterThanOrEqualTo('reservedDate', moment(reservedDate[0]).startOf('day').toDate())
    ticketQuery.lessThan('reservedDate', moment(reservedDate[reservedDate.length - 1]).endOf('day').toDate())
    ticketQuery.equalTo('marketType', order.get('marketType'))
    ticketQuery.limit(99999)
    const checkTicket = await ticketQuery.find()
    const nameCheckArr = checkTicket.map(item => item.get('lockName'))
    console.log('nameCheckArr', nameCheckArr)
    await Parse.Object.fetchAll(places)
    const found = places.some(r => nameCheckArr.includes(r.get('name')))
    console.log('found duplicate =>', found)
    if (found) {
        return { status: 'error', msg: 'ตรวจพบล็อคซ้ำในวันที่ต้องการเลื่อน' }
    }

    const tempReservedDate = order.get('reservedDate')
    if (!order.get('tempReservedDate')) {
        order.set('tempReservedDate', tempReservedDate)
    }
    order.set('reservedDate', reservedDate)
    order.set('fromDate', fromDate)
    order.set('toDate', toDate)
    order.set('note', note)
    try {
        await order.save()
        let query = new Parse.Query('Ticket');
        query.equalTo('order', order)
        const res = await query.find()
        let index = 0
        let tickets = []
        for (const date of tempReservedDate) {
            const arr = res.filter((obj) => {
                return obj.get('reservedDateStr') === date
            })
            for (const ticket of arr) {
                console.log('ticket', ticket.get('lockName'), 'date', ticket.get('reservedDateStr'), 'new', reservedDate[index])
                ticket.set('reservedDateStr', reservedDate[index])
                ticket.set('reservedDate', moment(reservedDate[index]).startOf('day').toDate())
                tickets.push(ticket)
            }
            index++
        }
        await Parse.Object.saveAll(tickets)
        return { status: 'success', msg: 'OK' }
    } catch (error) {
        return { status: 'error', msg: error.message }
    }
}


export const queryObjects2 = async (className, key, value, limit, skip, dateFilter, isTrash) => {
    init()
    const ClassName = Parse.Object.extend(className);
    const query = new Parse.Query(ClassName);
    query.limit(limit)
    query.skip(skip)
    if (isTrash) {
        query.equalTo('isTrash', isTrash)
    } else {
        query.doesNotExist('isTrash')
    }
    if (dateFilter.length !== 0) {
        query.greaterThanOrEqualTo('fromDate', dateFilter[0].startOf('day').toDate());
        query.lessThanOrEqualTo('toDate', dateFilter[1].endOf('day').toDate());
    }
    if (value !== "") {
        query.contains(key, value);
    }
    query.descending("createdAt");
    const count = await query.count();
    const results = await query.find();
    var objs = []
    results.map((obj) => {
        const json = obj.toJSON()
        json.parseObject = obj
        objs.push(json)
    })
    console.log(`results length:${results}`)
    return { results: objs, count: count }
}

export const createOrder = async (data) => {
    init()
    const ClassName = Parse
        .Object
        .extend('Order');
    const Ticket = Parse
        .Object
        .extend('Ticket');
    const RandomTicket = Parse
        .Object
        .extend('RandomTicket');
    console.log(`createOrder data reservedDate`, data.reservedDate)
    const obj = new ClassName();
    const res = await obj.save(data)
    let tickets = []
    if (res) {
        data
            .reservedDate
            .map(async (date) => {
                data
                    .places
                    .map(async (lock) => {
                        const ticket = new Ticket();
                        ticket.set('reservedDate', moment(date).toDate())
                        ticket.set('reservedDateStr', date)
                        ticket.set('lock', lock)
                        ticket.set('lockName', lock.get('name'))
                        ticket.set('order', res)
                        ticket.set('user', data.user)
                        ticket.set('marketType', data.marketType)
                        tickets.push(ticket)

                        const query = new Parse.Query(RandomTicket);
                        query.equalTo('lockName', lock.get('name'))
                        query.equalTo('marketType', data.marketType)
                        query.equalTo('reservedDateStr', date)
                        query.limit(9999)
                        const results = await query.find()
                        await Parse.Object.destroyAll(results)

                    })
            })
        const saveAllRes = await Parse
            .Object
            .saveAll(tickets)
        return { status: 'success', msg: 'Create Order Success' }
    } else {
        return { status: 'error', msg: 'Cannot Create Order' }
    }
}

export const getConfig = async () => {
    init()
    const config = await Parse.Config.get()
    return config
}
export const saveConfig = async (data) => {
    init()
    console.log('saveConfig', data)
    const res = await Parse.Config.save(data, { useMasterKey: true }).catch(error => {
        return { type: 'error', msg: error.message }
    })
    return { type: 'success', msg: "Save done!", config: res }

}


export const getReportOrder = async (dateFilter) => {
    init()
    const ClassName = Parse.Object.extend('Order');
    const query = new Parse.Query(ClassName);
    query.limit(99999)
    query.doesNotExist('isTrash')
    query.equalTo('paymentStatus', 'completed')
    if (dateFilter.length !== 0) {
        query.greaterThanOrEqualTo('fromDate', dateFilter[0].startOf('day').toDate());
        query.lessThanOrEqualTo('toDate', dateFilter[1].endOf('day').toDate());
    }
    query.descending("createdAt");
    const results = await query.find();
    let total = 0
    const objs = results.map(async obj => {
        const user = obj.get('user')
        await user.fetch()
        const lockName = await getLockName(obj.toJSON().places)
        const reservedDate = obj.get('reservedDate')
        const facilityData = obj.get('facilityData')
        const reservedDateStr = reservedDate.length === 1 ? reservedDate[0] : `${reservedDate[0]}-${reservedDate[reservedDate.length - 1]}`
        const electricity = facilityData.electricity === 0 ? `` : `\nค่าไฟ ${facilityData.electricity}`
        const fan = facilityData.fan === 0 ? `` : `\nค่าพัดลม ${facilityData.fan}`
        const tolley = facilityData.tolley === 0 ? `` : `\nค่าฝากของ ${facilityData.tolley}`
        const tolleyService = facilityData.tolleyService === 0 ? `` : `\nค่าเข็นรถ ${facilityData.tolleyService}`
        const fee = facilityData.fee === 0 ? `` : `\nค่าปรับ ${facilityData.fee}`
        const car = facilityData.car === 0 ? `` : `\nค่าจอดรถ ${facilityData.car}`
        const tent = facilityData.tent === 0 ? `` : `\nค่าเต็นท์ ${facilityData.tent}`
        total += obj.get('total')
        const detail = `ค่าล็อค ${obj.get('totalLock')}${electricity}${fan}${tolley}${tolleyService}${tent}${car}${fee}`
        const data = {
            'วันที่สั่งซื้อ': moment(obj.get('createdAt')).format('YYYY-MM-DD'),
            'วันที่จองล็อค': reservedDateStr,
            'ชื่อ': user.get('name'),
            'เลขที่ล็อค': lockName,
            'รายการ': detail,
            'ตลาด': obj.get('marketType') === 0 ? 'Zeer Street' : 'เซียร์ รังสิต',
            'เป็นเงิน': obj.get('total'),
        }
        return data
    })
    const tes = await Promise.all(objs)
    console.log(`results length:${tes} total:${total}`)
    tes.push({
        'วันที่สั่งซื้อ': '',
        'วันที่จองล็อค': '',
        'ชื่อ': '',
        'เลขที่ล็อค': '',
        'รายการ': '',
        'ตลาด': 'รวม',
        'เป็นเงิน': total,
    })
    return tes
}

export const getCurrentUser = async () => {
    init()
    const user = await Parse.User.current();
    return user
}


export const converseReportOrder = async (orders) => {
    const results = orders.map(obj => {
        return obj.parseObject
    })
    const objs = results.map(async obj => {
        const user = obj.get('user')
        await user.fetch()
        const lockName = obj.toJSON().places ? await getLockName(obj.toJSON().places) : obj.get('lockName')
        const reservedDate = obj.get('reservedDate')
        const facilityData = obj.get('facilityData')
        const reservedDateStr = reservedDate.length === 1 ? moment(reservedDate[0]).format('DD/MM/YYYY') : `${moment(reservedDate[0]).format('DD/MM/YYYY')} - ${moment(reservedDate[reservedDate.length - 1]).format('DD/MM/YYYY')}`
        const totalLock = obj.get('totalLock')
        const payStatus = obj.get('paymentStatus') === 'canceled' ? '(ยกเลิก)' : ''
        const total = obj.get('total')
        const vat = (total * 7 / 107)
        const gross = total - parseFloat(vat.toFixed(2))
        const data = {
            'วันที่สั่งซื้อ': moment(obj.get('createdAt')).format('DD/MM/YYYY'),
            'วันที่จองล็อค': reservedDateStr,
            'เลขที่ใบเสร็จ': obj.get('orderNumber'),
            'ชื่อ': `${user.get('name')} ${payStatus}`,
            'ตลาด': obj.get('marketType') === 0 ? 'Zeer Street' : 'เซียร์ รังสิต',
            'เลขที่ล็อค': lockName,
            'ค่าล็อค': totalLock,
            'ค่าไฟ': facilityData.electricity,
            'ค่าพัดลม': facilityData.fan,
            'ค่าฝากของ': facilityData.tolley,
            'ค่าเต็นท์': facilityData.tent,
            'ค่าเข็นรถ': facilityData.tolleyService,
            'ค่าจอดรถ': facilityData.car,
            'ค่าปรับ': facilityData.fee,
            'จำนวนเงินก่อนภาษี': gross,
            'ภาษีมูลค่าเพิ่ม': parseFloat(vat.toFixed(2)),
            'เป็นเงิน': total,
            'หมายเหตุ': obj.get('note')
        }
        return data
    })
    const tes = await Promise.all(objs)
    console.log(`results length:${tes}`)
    const summary = {
        'วันที่สั่งซื้อ': '',
        'วันที่จองล็อค': '',
        'เลขที่ใบเสร็จ': '',
        'ชื่อ': '',
        'ตลาด': '',
        'เลขที่ล็อค': 'รวม',
        'ค่าล็อค': tes.sumByKey('ค่าล็อค'),
        'ค่าไฟ': tes.sumByKey('ค่าไฟ'),
        'ค่าพัดลม': tes.sumByKey('ค่าพัดลม'),
        'ค่าฝากของ': tes.sumByKey('ค่าฝากของ'),
        'ค่าเต็นท์': tes.sumByKey('ค่าเต็นท์'),
        'ค่าเข็นรถ': tes.sumByKey('ค่าเข็นรถ'),
        'ค่าจอดรถ': tes.sumByKey('ค่าจอดรถ'),
        'ค่าปรับ': tes.sumByKey('ค่าปรับ'),
        'จำนวนเงินก่อนภาษี': tes.sumByKey('จำนวนเงินก่อนภาษี'),
        'ภาษีมูลค่าเพิ่ม': tes.sumByKey('ภาษีมูลค่าเพิ่ม'),
        'เป็นเงิน': tes.sumByKey('เป็นเงิน'),
        'หมายเหตุ': ''
    }
    tes.push(summary)
    return tes
}


export const converseReportVat = async (orders) => {
    const results = orders.map(obj => {
        return obj.parseObject
    })
    const objs = results.map(async obj => {
        const user = obj.get('user')
        await user.fetch()
        const lockName = obj.toJSON().places ? await getLockName(obj.toJSON().places) : obj.get('lockName')
        const total = obj.get('total')
        const vat = (total * 7 / 107)
        const gross = total - parseFloat(vat.toFixed(2))
        const payStatus = obj.get('paymentStatus') === 'canceled' ? '(ยกเลิก)' : ''
        const data = {
            'วัน/เดือน/ปี': moment(obj.get('createdAt')).format('DD/MM/YYYY'),
            'เลขที่/เล่มที่': obj.get('orderNumber'),
            'ชื่อผู้ซื้อสินค้า/ผู้รับบริการ': `${lockName} ${user.get('name')} ${payStatus}`,
            'เลขประจำตัวผู้เสียภาษี': user.get('nationalId'),
            'สนญ/สาขาที่': user.get('branch') || '',
            'มูลค่าสินค้าหรือบริการ': gross,
            'มูลค่าสินค้าไม่คิดVat': 0,
            'จำนวนเงินภาษีมูลค่าเพิ่ม': parseFloat(vat.toFixed(2)),
            'ยอดรวม': total,
            'หมายเหตุ': obj.get('note')
        }
        return data
    })
    const tes = await Promise.all(objs)
    console.log(`results length:${tes}`)
    const summary = {
        'วัน/เดือน/ปี': 'รวม',
        'เลขที่/เล่มที่': '',
        'ชื่อผู้ซื้อสินค้า/ผู้รับบริการ': '',
        'เลขประจำตัวผู้เสียภาษี': '',
        'สนญ/สาขาที่': '',
        'มูลค่าสินค้าหรือบริการ': tes.sumByKey('มูลค่าสินค้าหรือบริการ'),
        'มูลค่าสินค้าไม่คิดVat': 0,
        'จำนวนเงินภาษีมูลค่าเพิ่ม': tes.sumByKey('จำนวนเงินภาษีมูลค่าเพิ่ม'),
        'ยอดรวม': tes.sumByKey('ยอดรวม'),
        'หมายเหตุ': ''
    }
    tes.push(summary)
    return tes
}

export const converseToInvoice = async (orders) => {
    const results = orders.map(obj => {
        return obj.parseObject
    })
    const objs = results.map(async obj => {
        const user = obj.get('user')
        await user.fetch()
        const lockName = obj.toJSON().places ? await getLockName(obj.toJSON().places) : obj.get('lockName')
        const total = obj.get('total')
        const totalLock = obj.get('totalLock')
        const facilityData = obj.get('facilityData')
        const vat = (total * 7 / 107)
        const gross = total - parseFloat(vat.toFixed(2))
        const payStatus = obj.get('paymentStatus') === 'canceled' ? '(ยกเลิก)' : ''
        let data = {
            orderNumber: obj.get('orderNumber'),
            date: moment(obj.get('createdAt')).format('DD/MM/YYYY'),
            address: `${user.get('address')} ${user.get('tumbol')} ${user.get('district')} ${user.get('province')}  `,
            customer: `${lockName} ${user.get('name')} ${payStatus}`,
            nationalId: user.get('nationalId'),
            branch: user.get('branch') || '',
            amount: gross,
            vat: parseFloat(vat.toFixed(2)),
            total: total,
        }
        let index = 1
        let item = [
            {
                index: index,
                description: 'ค่าล็อค',
                amount: totalLock - parseFloat((totalLock * 7 / 107).toFixed(2)),
                vat: (totalLock * 7 / 107).toFixed(2),
                total: totalLock.toFixed(2)
            }
        ]
        index += 1
        if (facilityData.electricity !== 0) {
            item.push({
                index: index,
                description: 'ค่าไฟ',
                amount: facilityData.electricity - parseFloat((facilityData.electricity * 7 / 107).toFixed(2)),
                vat: (facilityData.electricity * 7 / 107).toFixed(2),
                total: facilityData.electricity.toFixed(2)
            })
            index += 1
        }
        if (facilityData.fan !== 0) {
            item.push({
                index: index,
                description: 'ค่าพัดลม',
                amount: facilityData.fan - parseFloat((facilityData.fan * 7 / 107).toFixed(2)),
                vat: (facilityData.fan * 7 / 107).toFixed(2),
                total: facilityData.fan.toFixed(2)
            })
            index += 1
        }
        if (facilityData.tent !== 0) {
            item.push({
                index: index,
                description: 'ค่าเต็นท์',
                amount: facilityData.tent - parseFloat((facilityData.tent * 7 / 107).toFixed(2)),
                vat: (facilityData.tent * 7 / 107).toFixed(2),
                total: facilityData.tent.toFixed(2)
            })
            index += 1
        }
        if (facilityData.tolley !== 0) {
            item.push({
                index: index,
                description: 'ค่าฝากของ',
                amount: facilityData.tolley - parseFloat((facilityData.tolley * 7 / 107).toFixed(2)),
                vat: (facilityData.tolley * 7 / 107).toFixed(2),
                total: facilityData.tolley.toFixed(2)
            })
            index += 1
        }
        if (facilityData.fan !== 0) {
            item.push({
                index: index,
                description: 'ค่าพัดลม',
                amount: facilityData.fan - parseFloat((facilityData.fan * 7 / 107).toFixed(2)),
                vat: (facilityData.fan * 7 / 107).toFixed(2),
                total: facilityData.fan.toFixed(2)
            })
            index += 1
        }
        if (facilityData.tolleyService !== 0) {
            item.push({
                index: index,
                description: 'ค่าเข็นรถเข็น',
                amount: facilityData.tolleyService - parseFloat((facilityData.tolleyService * 7 / 107).toFixed(2)),
                vat: (facilityData.tolleyService * 7 / 107).toFixed(2),
                total: facilityData.tolleyService.toFixed(2)
            })
            index += 1
        }
        if (facilityData.car !== 0) {
            item.push({
                index: index,
                description: 'ค่าจอดรถ',
                amount: facilityData.car - parseFloat((facilityData.car * 7 / 107).toFixed(2)),
                vat: (facilityData.car * 7 / 107).toFixed(2),
                total: facilityData.car.toFixed(2)
            })
            index += 1
        }
        if (facilityData.fee !== 0) {
            item.push({
                index: index,
                description: 'ค่าปรับ',
                amount: facilityData.fee - parseFloat((facilityData.fee * 7 / 107).toFixed(2)),
                vat: (facilityData.fee * 7 / 107).toFixed(2),
                total: facilityData.fee.toFixed(2)
            })
            index += 1
        }

        for (let index = 0; index < (20 - item.length); index++) {
            item.push({
                index: ' ',
                description: ' ',
                amount: ' ',
                vat: ' ',
                total: ' '
            })
        }




        data.items = item
        return data
    })
    const tes = await Promise.all(objs)
    return tes
}

export const converseReportChangeDate = async (orders) => {
    const results = orders.map(obj => {
        return obj.parseObject
    })
    const objs = results.map(async obj => {
        const user = obj.get('user')
        await user.fetch()
        const lockName = obj.toJSON().places ? await getLockName(obj.toJSON().places) : obj.get('lockName')
        const total = obj.get('total')
        const vat = (total * 7 / 107)
        const gross = total - parseFloat(vat.toFixed(2))
        const payStatus = obj.get('paymentStatus') === 'canceled' ? '(ยกเลิก)' : ''
        const tempReservedDate = obj.get('tempReservedDate')
        const reservedDate = obj.get('reservedDate')

        const data = {
            'เลขที่ใบเสร็จ': obj.get('orderNumber') || obj.get('tempOrderNumber'),
            'ชื่อผู้ซื้อสินค้า/ผู้รับบริการ': `${lockName} ${user.get('name')} ${payStatus}`,
            'วันที่จองเดิม': tempReservedDate.length === 1 ? moment(tempReservedDate[0]).format('DD/MM/YYYY') :
                `${moment(tempReservedDate[0]).format('DD/MM/YYYY')} - ${moment(tempReservedDate[tempReservedDate.length - 1]).format('DD/MM/YYYY')}`,
            'วันที่เลื่อน': reservedDate.length === 1 ? moment(reservedDate[0]).format('DD/MM/YYYY') :
                `${moment(reservedDate[0]).format('DD/MM/YYYY')} - ${moment(reservedDate[reservedDate.length - 1]).format('DD/MM/YYYY')}`,
            'หมายเหตุ': obj.get('note')
        }
        return data
    })
    const tes = await Promise.all(objs)
    console.log(`results length:${tes}`)
    return tes
}

export const getCustomerCount = async (momentRange) => {
    init()
    const ClassName = Parse.Object.extend('User');
    const query = new Parse.Query(ClassName);
    query.limit(99999)
    query.doesNotExist('isTrash')
    query.equalTo('isAdmin', false)
    query.greaterThanOrEqualTo('createdAt', momentRange[0].toDate())
    query.lessThan('createdAt', momentRange[1].toDate())
    const results = await query.count();
    console.log(`results length:${results}`)
    return results
}

export const getVIPCustomerCount = async (momentRange) => {
    init()
    const ClassName = Parse.Object.extend('User');
    const query = new Parse.Query(ClassName);
    query.limit(99999)
    query.doesNotExist('isTrash')
    query.equalTo('isAdmin', false)
    query.equalTo('customerType', 1)
    query.greaterThanOrEqualTo('createdAt', momentRange[0].toDate())
    query.lessThan('createdAt', momentRange[1].toDate())
    const results = await query.count();
    console.log(`results length:${results}`)
    return results
}

export const getRandomOrderCount = async () => {
    init()
    const ClassName = Parse.Object.extend('RandomOrder');
    const query = new Parse.Query(ClassName);
    query.limit(99999)
    query.doesNotExist('isTrash')
    query.doesNotExist('isOutOfStock')
    query.greaterThanOrEqualTo('fromDate', moment().startOf('day').toDate());
    query.lessThanOrEqualTo('toDate', moment().endOf('day').toDate());
    const results = await query.count();
    console.log(`results length:${results}`)
    return results
}

export const getRandomOrderOutOfStockCount = async () => {
    init()
    const ClassName = Parse.Object.extend('RandomOrder');
    const query = new Parse.Query(ClassName);
    query.limit(99999)
    query.doesNotExist('isTrash')
    query.greaterThanOrEqualTo('fromDate', moment().startOf('day').toDate());
    query.lessThanOrEqualTo('toDate', moment().endOf('day').toDate());
    query.equalTo('isOutOfStock', true)
    const results = await query.count();
    console.log(`results length:${results}`)
    return results
}

export const getIncomeReport = async (className, momentRange) => {
    init()
    const ClassName = Parse.Object.extend(className);
    const query = new Parse.Query(ClassName);
    query.limit(999999)
    query.skip(0)
    query.descending("createdAt");
    query.doesNotExist('isTrash')
    query.greaterThanOrEqualTo('fromDate', momentRange[0].toDate());
    query.lessThanOrEqualTo('toDate', momentRange[1].toDate());
    query.equalTo('paymentStatus', 'completed')
    const results = await query.find();
    var objs = []
    results.map((obj) => {
        const json = obj.toJSON()
        objs.push(json)
    })
    const zs = objs.filter((obj) => {
        return obj.marketType === 0
    })
    const zsCount = zs.sumByKey('total')
    const zr = objs.filter((obj) => {
        return obj.marketType === 1
    })
    const zrCount = zr.sumByKey('total')


    console.log(`results length:${objs.length} | ${JSON.stringify(objs[0])}`)
    return { zs: zsCount, zr: zrCount }
}

export const adminLogger = async (type, activity, data) => {
    init()
    const user = await getCurrentUser()
    const Activity = Parse.Object.extend('Activity');
    const obj = new Activity()
    obj.set('user', user)
    obj.set('type', type)
    obj.set('activity', activity)
    if (data) {
        obj.set('data', data)
    }
    try {
        const res = await obj.save()
        return { type: 'success', msg: 'Ok', object: res }
    } catch (error) {
        return { type: 'error', msg: 'Error! :' + error.message }
    }
}

export const listenTransaction = async () => {
    let query = new Parse.Query('Transaction');
    let subscription = await query.subscribe();
    return subscription

}

export const getOrderFromDateForMonitor = async (dateStr, marketType) => {
    init()
    let query = new Parse.Query('Order');
    query.equalTo('paymentStatus', 'completed')
    if (marketType) {
        query.equalTo('marketType', marketType)
    }
    query.equalTo('reservedDate', dateStr)
    query.limit(99999)
    const results = await query.find();
    var objs = []
    results.map((obj) => {
        const json = obj.toJSON()
        json.parseObject = obj
        objs.push(json)
    })
    console.log(`results length:${results}`)
    return { results: objs }

}

export const getObjectsByPagination = async (req) => {
    init()
    const { className, pageSize, pageIndex, queryKey, searchValue, dateFilter, isTrash,
        queryStatus, provider, extensQueryKey, extensQueryValue, sortKey, sortType } = req
    const skip = (pageSize * pageIndex) - pageSize
    const ClassName = Parse.Object.extend(className);
    const query = new Parse.Query(ClassName);
    sortType === 'ascending' ? query.ascending(sortKey || "createdAt") : query.descending(sortKey || "createdAt");
    if (isTrash) {
        query.equalTo('isTrash', isTrash)
    } else {
        query.doesNotExist('isTrash')
    }
    if (dateFilter && dateFilter.length !== 0) {
        query.greaterThanOrEqualTo('createdAt', new Date(dateFilter[0].startOf('day').toString()));
        query.lessThan('createdAt', new Date(dateFilter[1].endOf('day').toString()));
    }
    if (searchValue !== '') {
        query.contains(queryKey, searchValue)
    }
    if (queryStatus) {
        query.equalTo('status', queryStatus)
    }
    if (provider) {
        query.equalTo('provider', provider)
    }
    if (extensQueryKey) {
        query.equalTo(extensQueryKey, extensQueryValue)
    }
    const total = await query.count();
    query.limit(pageSize)
    query.skip(skip)
    const results = await query.find({ useMasterKey: true });
    var objs = []
    results.map((obj) => {
        const json = obj.toJSON()
        json.parseObject = obj
        objs.push(json)
    })
    //console.log(`results length:${objs.length} | ${JSON.stringify(objs[0])}`)
    return { data: objs, total }
}