import React, { Component } from 'react';
import { connect } from 'react-redux';
import Popover from '../../components/uielements/popover';
import IntlMessages from '../../components/utility/intlMessages';
import { Button,Input } from 'antd';
import axios from "axios";
import {message} from 'antd'
import userpic from '../../image/user1.png';
import authAction from '../../redux/auth/actions';
import TopbarDropdownWrapper from './topbarDropdown.style';
import config from "../../config";
import {adminLogger} from '../../helpers/parseHelper'
const verifyCode = '97dd70d3-da0a-432a-9dc9-01ef5a06c6a9'
const { logout } = authAction;

class TopbarLogout extends Component {
  constructor(props) {
    super(props);
    this.handleVisibleChange = this.handleVisibleChange.bind(this);
    this.hide = this.hide.bind(this);

    this.state = {
      visible: false,
      code:''
    };
  }
  hide() {
    this.setState({ visible: false });
  }
  handleVisibleChange() {
    this.setState({ visible: !this.state.visible });
  }
  random = async () => {
    this.setState({isOnReSync: true})
    const res = await axios.get(`${config.url}/api/1.0/randomOrder/random`)
    console.log(res)
    if(res.status === 200) {
      this.setState({isOnReSync: false})
      await adminLogger('OPERATION','REQUEST_RANDOM_LOCK')
      message.success('ทำการ Random เรียบร้อย');
    }else{
      this.setState({isOnReSync: false})
      message.error('Random ERROR');
    }
  }
  verify = async () => {
    const res = await axios.post(`${config.url}/verify`,{code:this.state.code,isAdminAccess:true})
    console.log(res)
    if(res.status === 200){
      if(res.data === true){
        message.info('ทำการ Random');
        this.random()
        this.hide()
        this.setState({code:''})
      }else{
        message.info('Code ไม่ถูกต้อง');
      }
    }else{
      message.info('มีบางอย่างผิดปกติ');
    }
  }
  render() {
    const content = (
      <TopbarDropdownWrapper className="isoUserDropdown" style={{width:240,padding:16}}>
        <Input placeholder="Enter Code" style={{padding:16}} value={this.state.code} onChange={(v)=>{
          console.log(v.target.value)
          this.setState({code:v.target.value})
        }} onPressEnter={this.verify}/>
        <Button type="primary"
        style={{marginTop:8}}
        onClick={this.verify}>
        Verify
      </Button>
      </TopbarDropdownWrapper>
    );
      
    return (
      <div>
     <Popover content={content} title="Enter Code" trigger="click" visible={this.state.visible} onVisibleChange={this.handleVisibleChange}>
    <Button 
      type="primary"
        style={{marginTop:8}}
        onClick={()=>{
          console.log('Re-Sync Data');
          // message.info('ทำการ Random');
          // this.random()
        }}>
        Random
      </Button>
      </Popover>
      <Button 
        style={{marginTop:8}}
        type="link"
        onClick={()=>{

          this.props.logout()
        }}>
        Log out
      </Button>
      </div>
    );
  }
}
export default connect(
  null,
  { logout }
)(TopbarLogout);
