import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import asyncComponent from '../../helpers/AsyncFunc';

const routes = [
  {
    path: '',
    component: asyncComponent(() => import('../dashboard')),
  },
  {
    path: 'content',
    component: asyncComponent(() => import('../Content')),
  },
  {
    path: 'create-content',
    component: asyncComponent(() => import('../Content/create')),
  },
  {
    path: 'edit-content',
    component: asyncComponent(() => import('../Content/edit')),
  },
  {
    path: 'trash-content',
    component: asyncComponent(() => import('../Content/trash')),
  },
  {
    path: 'promotion',
    component: asyncComponent(() => import('../Promotion')),
  },
  {
    path: 'trash-promotion',
    component: asyncComponent(() => import('../Promotion/trash')),
  },
  {
    path: 'create-promotion',
    component: asyncComponent(() => import('../Promotion/create')),
  },
  {
    path: 'edit-promotion',
    component: asyncComponent(() => import('../Promotion/edit')),
  },
  {
    path: 'zone',
    component: asyncComponent(() => import('../Zone')),
  },
  {
    path: 'trash-zone',
    component: asyncComponent(() => import('../Zone/trash')),
  },
  {
    path: 'create-zone',
    component: asyncComponent(() => import('../Zone/create')),
  },
  {
    path: 'edit-zone',
    component: asyncComponent(() => import('../Zone/edit')),
  },
  {
    path: 'orderTransaction',
    component: asyncComponent(() => import('../OrderTransaction')),
  },
  {
    path: 'invoice',
    component: asyncComponent(() => import('../Invoice')),
  },
  {
    path: 'paymentTransaction',
    component: asyncComponent(() => import('../AdditionalPayment')),
  },
  {
    path: 'randomOrder',
    component: asyncComponent(() => import('../RandomOrder')),
  },
  {
    path: 'transaction',
    component: asyncComponent(() => import('../Transaction')),
  },
  {
    path: 'customer',
    component: asyncComponent(() => import('../Customer')),
  },
  {
    path: 'trash-customer',
    component: asyncComponent(() => import('../Customer/trash')),
  },
  {
    path: 'create-customer',
    component: asyncComponent(() => import('../Customer/create')),
  },
  {
    path: 'edit-customer',
    component: asyncComponent(() => import('../Customer/edit')),
  },
  {
    path: 'info-customer',
    component: asyncComponent(() => import('../Customer/info')),
  },
  {
    path: 'payment-info-customer',
    component: asyncComponent(() => import('../Customer/paymentInfo')),
  },
  {
    path: 'lock-monitor',
    component: asyncComponent(() => import('../LockMonitor')),
  },
  {
    path: 'summary',
    component: asyncComponent(() => import('../Summary')),
  },
  {
    path: 'summary-customer',
    component: asyncComponent(() => import('../Summary/customer')),
  },
  {
    path: 'summary-additional',
    component: asyncComponent(() => import('../Summary/additional')),
  },
  {
    path: 'summary-customer-history',
    component: asyncComponent(() => import('../Summary/customerHistory')),
  },
  {
    path: 'setting',
    component: asyncComponent(() => import('../SettingConfig')),
  },
  {
    path: 'admin',
    component: asyncComponent(() => import('../Admin')),
  },
  {
    path: 'create-admin',
    component: asyncComponent(() => import('../Admin/create')),
  },
  {
    path: 'edit-admin',
    component: asyncComponent(() => import('../Admin/edit')),
  },
  {
    path: 'document-web',
    component: asyncComponent(() => import('../Document/web')),
  },
  {
    path: 'document-app',
    component: asyncComponent(() => import('../Document/app')),
  },
  
];

class AppRouter extends Component {
  render() {
    const { url, style } = this.props;
    return (
      <div style={style}>
        {routes.map(singleRoute => {
          const { path, exact, ...otherProps } = singleRoute;
          return (
            <Route
              exact={exact === false ? false : true}
              key={singleRoute.path}
              path={`${url}/${singleRoute.path}`}
              {...otherProps}
            />
          );
        })}
      </div>
    );
  }
}

export default AppRouter;
