const options = [
  {
    key: '',
    label: 'sidebar.dashboard',
    leftIcon: 'ion-arrow-graph-up-right',
  },
  {
    key: 'lock-monitor',
    label: 'ผังล็อครายวัน',
    leftIcon: 'ion-map'
  },
  {
    key: 'randomOrder',
    label: 'การสุ่มออเดอร์',
    leftIcon: 'ion-ios-box-outline'
  },
  {
    key: 'admin',
    label: 'จัดการ Admin',
    leftIcon: 'ion-ios-contact',
    children: [
      {
        key: "admin",
        label: "ข้อมูลทั้งหมด",
      },
      {
        key: "create-admin",
        label: "สร้าง Admin",
      }
    ]
  },
  {
    key: 'zone',
    label: 'จัดการล็อค',
    leftIcon: 'ion-bag',
    children: [
      {
        key: "zone",
        label: "ข้อมูลทั้งหมด",
      },
      {
        key: "create-zone",
        label: "สร้างล็อค",
      },
      {
        key: "trash-zone",
        label: "ถังขยะ",
      }
    ]
  },
  {
    key: 'customer',
    label: 'ข้อมูลลูกค้า',
    leftIcon: 'ion-android-people',
    children: [
      {
        key: "customer",
        label: "ยืนยันลูกค้าลงทะเบียน",
      },
      {
        key: "info-customer",
        label: "ข้อมูลลูกค้า",
      },
      {
        key: "trash-customer",
        label: "ถังขยะ",
      }
    ]
  },
  {
    key: 'orderTransaction',
    label: 'ข้อมูลออเดอร์',
    leftIcon: 'ion-android-checkmark-circle'
  },
  {
    key: 'paymentTransaction',
    label: 'การจ่ายเงินเพิ่มเติม',
    leftIcon: 'ion-card'
  },{
    key: 'transaction',
    label: 'ข้อมูลการชำระเงิน',
    leftIcon: 'ion-social-bitcoin'
  },
  {
    key: 'summary',
    label: 'ข้อมูลบัญชี',
    leftIcon: 'ion-cash',
    children: [
      {
        key: "summary",
        label: "ข้อมูลรายรับ",
      },
      {
        key: "summary-customer",
        label: "ข้อมูลรายรับรายบุคคล",
      },
      {
        key: "summary-additional",
        label: "ข้อมูลรายรับเพิ่มเติม",
      }
    ]
  },
  {
    key: 'promotion',
    label: 'Promotion',
    leftIcon: 'ion-android-happy',
    children: [
      {
        key: "create-promotion",
        label: "สร้าง Promotion",
      },
      {
        key: "promotion",
        label: "ข้อมูลทั้งหมด",
      },
      {
        key: "trash-promotion",
        label: "ถังขยะ",
      }
    ]
  },
  {
    key: 'content',
    label: 'Content',
    leftIcon: 'ion-ios-compose-outline',
    children: [
      {
        key: "content",
        label: "ข้อมูลทั้งหมด",
      },
      {
        key: "create-content",
        label: "สร้าง Content",
      },
      {
        key: "trash-content",
        label: "ถังขยะ",
      }
    ]
  },{
    key: 'document',
    label: 'คู่มือการใช้',
    leftIcon: 'ion-document-text',
    children: [
      {
        key: "document-web",
        label: "คู่มือการใช้ Admin Web",
      },
      {
        key: "document-app",
        label: "คู่มือการใช้ Application",
      },
    ]
  },{
    key: 'setting',
    label: 'ตั้งค่า',
    leftIcon: 'ion-ios-cog',
  },
];
export default options;
